<template>
    <div class="error-block">
        <img src="/icon/error_modal.svg" class="icon-error" alt="error" />
        <div><h2 class="error-title">{{ $t('errorModal.errorTitle') }}</h2></div>
        <span class="error-description"
            >{{ $t('errorModal.errorDescription') }} <a :href="`tel:${data.phone}`" class="phone_link">{{ data.phone }}</a
            >.</span
        >
        <div>
            <default-button size="medium" @click="againFormSend">{{ $t('errorModal.tryAgain') }}</default-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useContentPages } from '~/stores/homeStores';
import DefaultButton from '~/components/Button/DefaultButton.vue';

const contentPagesStore = useContentPages();
const data = computed(() => contentPagesStore.consultationTranslate);
const emit = defineEmits(['restart']);
const againFormSend = () => emit('restart');
</script>

<style lang="scss" scoped>
.error-block {
    max-width: 480px;
}
.icon-error {
    width: 5rem;
    height: 5rem;
}
.error-title {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
}
.error-description {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    text-align: center;
}
.error-block {
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    justify-content: center;
    align-items: center;
    max-width: 22.5rem;
    width: 100%;
}

.phone_link {
    color: var(--other-blue-48538-b, #48538b);
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
}
</style>
